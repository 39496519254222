$card-title-line-height: 24px;
.main-container {
  > .niagara-side-menu {
    @media (max-width: 1024px) {
      overflow-x: auto;
    }

    &:has(
        .customer-page,
        .service-account-detail-page,
        .device-details,
        .point-tabs,
        .point-details-tabs,
        .save-report-page,
        .project-device-page,
        .create-service-account-detail-card,
        .manage-service-account-page,
        .manage-access-card,
        .service-account,
        .service-account-detail-card,
        .audit-log-container
      ) {
      @media (max-width: 1024px) {
        overflow-x: hidden;
      }
    }

    > .sidebar {
      width: 270px;

      .ui.menu {
        width: 100%;
        padding: 18px 32px 60px 32px;
        display: flex;
        flex-direction: column;

        .sidebar-header-layout {
          font-weight: 800;
          font-size: 16px;
          line-height: 32px;
          letter-spacing: 2.5px;
          text-transform: uppercase;
          display: flex;
          vertical-align: middle;
          margin-bottom: 16px;

          button {
            margin-left: auto;
          }
        }

        .item {
          margin: 8px 0 8px 8px;
          padding: 0;
          padding-left: 8px;
          border-left: 2px solid transparent;

          .sidebar-label {
            color: #606060;
          }

          .sidebar-icon {
            position: absolute;
            right: 0;
            margin-right: 0;
          }

          &.active {
            background: none;
            border: none;
            border-left: 2px solid #1792e5;
            .sidebar-label {
              color: #303030;
            }
          }

          &:hover {
            background: none;
          }
        }

        .sidebar-footer-layout {
          margin-top: auto;

          .sidebar-footer-title {
            font-weight: 800;
            letter-spacing: 2px;
            text-transform: uppercase;
            color: #707070;
            margin: 0;
          }

          p {
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 8px;
          }

          a {
            color: #005eac;
            font-weight: 700;
            letter-spacing: 0.5px;
            font-size: 14px;
            line-height: 20px;
            display: flex;
            align-items: center;
            gap: 5px;
          }
        }
      }
    }
  }
  &:has(.sidebar-layout.collapse) {
    .content.content-container {
      width: calc(100% - 270px);
    }
  }
}

.sidebar-layout {
  &:not(.collapse) {
    .content.content-container {
      &:has(.customer-page, .service-account-detail-page) {
        @media (max-width: 767px) {
          &:after {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(224, 224, 224, 0.5);
            z-index: 1;
          }
        }
      }
    }
  }
}

.content-container {
  width: calc(100% - 270px);
  height: 100vh;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  $page-header-height: 86px;
  $copyright-height: 40px;

  &:has(
      .customer-page,
      .service-account-detail-page,
      .device-details,
      .point-tabs,
      .point-details-tabs,
      .save-report-page,
      .project-device-page,
      .create-service-account-detail-card,
      .manage-service-account-page,
      .manage-access-card,
      .service-account,
      .service-account-detail-card,
      .audit-log-container
    ) {
    @media (max-width: 1024px) {
      min-width: 100% !important;
      .copyright-container {
        font-size: 11px;
      }
    }
  }
  @media (max-width: 1024px) {
    min-width: 1280px !important;
  }
  @media screen and (max-height: 450px) {
    min-height: 768px;
  }
  > .content-loader.ui.loader-wrap.loading {
    position: relative;
    height: calc(100% - $page-header-height - $copyright-height);
  }

  .copyright-container {
    margin-top: auto;
    line-height: 40px;
    font-size: 12px;
    font-weight: 500;
    color: #707070;
    text-align: center;
  }

  .card-title {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    line-height: $card-title-line-height;
    letter-spacing: 2.5px;
    text-overflow: ellipsis;
  }

  .flex-fill {
    flex: 1;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .capitalize {
    text-transform: capitalize;
  }
}

.ui.divider {
  border: 1px solid #f7f7f7 !important;
}

::-webkit-scrollbar {
  width: 16px !important;
  height: 16px !important;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.02) !important;
  border: none !important;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5) !important;
  border-radius: 8px !important;
  border: 4px solid transparent !important;
  background-clip: content-box !important;
}

* {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.02);
}

.fixed-header,
.fixed-footer {
  width: 100%;
}

.button-container {
  display: flex;
  gap: 12px;
}

.niagara-data-table .forge-datatable-wrap {
  &:last-child {
    height: 100% !important;
  }

  &:not(:last-child) {
    height: calc(100% - 80px) !important;
  }
}

.niagara-table.ui.table tr td i {
  top: unset;
}

.niagara-input.full-width .ui.single-input {
  width: 100% !important;

  input {
    width: 100% !important;
  }
}

.alternate-tooltip.ui.tooltip.niagara-tooltip {
  color: #f0f0f0;
  background-color: #404040;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 8px;

  &::before {
    background-color: #404040 !important;
    border: none !important;
  }
}

.niagara-loader.ui.loader-wrap.loading {
  position: relative;
  height: 150px;
}

.niagara-button-2.disabled.pending {
  cursor: progress;
}

@media (max-width: 799px) {
  .ui.small.modal {
    min-width: 95% !important;
  }

  .ui.niagara-modal.modal:has(.scrollable-modal) {
    .scrollable-modal {
      min-width: 600px;
    }

    max-width: 100%;
    overflow: auto;
  }
}

@media screen and (orientation: landscape) {
  .ui.niagara-modal.modal:has(.scrollable-modal) {
    max-height: 90%;

    .scrollable-modal {
      height: 100%;
    }
  }
}

@media (max-width: 499px) {
  .ui.large.modal {
    width: 95% !important;
  }
}
.niagara-snackbar {
  right: 48px !important;
}