.show-columns-modal {
  padding-left: 0 !important;
  padding-right: 0 !important;

  .header {
    padding: 0 32px 8px 32px !important;
  }

  .default-items,
  .column-info {
    padding: 0;
    margin: 12px 0;
    margin-left: 32px;
    margin-right: 32px;
  }

  .item-selectors {
    overflow-y: auto;
    max-height: 200px;

    .item-selector {
      border-top: solid 2px #e0e0e0;
      height: 56px;
      padding: 20px 16px 20px 32px;
      display: flex;
      align-items: center;

      &:last-child {
        border-bottom: solid 2px #e0e0e0;
      }

      .niagara-checkbox {
        + label {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 200px;
        }
      }

      & > *:last-child {
        margin-left: auto;
      }
    }

    .list-container {
      display: flex;
      flex-direction: column;
    }

    .dragged {
      background-color: #ffffff;
      box-shadow: 1px 1px 5px rgba(192, 192, 192, 0.7);
      left: auto !important;
    }
  }
  .actions {
    justify-content: center;
    padding-right: 32px !important;
    .button-container {
      .niagara-button-2 {
        &.primary {
          padding: 0 53px;
        }
        &.secondary {
          padding: 0 53px;
          border: 1.5px solid #0071b3;
          color: #0071b3;
        }
      }
    }
  }
  div.item-selector:has(span.disabled) {
    background-color: #f4f4f4;
  }
  .seperator {
    margin: 0px;
    height: 1px;
    background-color: #e0e0e0;
  }
}

.dimmable:has(.dimmer .show-columns-modal.ui.modal.niagara-modal) .ui.page.modals.dimmer {
  padding-top: 8px;
  background-color: #f2f2f2;
  .ui.modal.niagara-modal.show-columns-modal {
    max-height: 100%;
    border-radius: 12px 12px 0 0 !important;
    width: 100% !important;
    margin: 0 !important;
    height: 100%;
    padding-top: 24px;
    .header {
      .modal-heading {
        order: 2;
        width: 100%;
        text-align: center;
        margin-right: 32px;
        font-size: 20px !important;
      }
    }
    .scrolling.content {
      max-height: 100%;
    }
    > div {
      height: 100%;
      .scrolling.content {
        height: calc(100% - 100px) !important;
        .modal-content {
          height: 100%;
          .item-selectors {
            max-height: calc(100% - 100px);
          }
        }
      }
    }
  }
}
